<template>
  <div class="d-flex flex-column justify-center align-center login-background" style="height: 100%;">
    <logo theme="dark" style="flex: none !important;" class="mb-4 mr-4 logo"/>
    <div class="title text-xs-center loading-text Animate-Opacity white--text"><i>{{$t('actions.loading')}}</i></div>
  </div>
</template>

<script>
export default {
  components: {
    Logo: () => import('@/components/app-components/Logo.vue')
  }
}
</script>

<style lang="scss">
.logo {
  max-height: 300px;
  width: auto;
}
.dark {
  fill: #ffffff;
}
.light {
  fill: rgba(0, 0, 0, 0.87);
}
.loading-text {
  position: absolute;
  bottom: 35%;
  transform: translateX(-50%);
  left: 50%;
  font-size: 1.5rem !important;
  font-weight: 100 !important;
}

</style>
